import AdSense from "../../Atoms/Adsense";
import About from "../../Components/About";
import Budgets from "../../Components/Budgets";
import ComunityProject from "../../Components/ComunityProject";
import { Copyright } from "../../Components/Copyright";
import Footer from "../../Components/Footer";
import Main from "../../Components/Main";
import Navbar from "../../Components/Navbar";
import Partner from "../../Components/Partner";
import PopUp from "../../Components/PopUp";
import Services from "../../Components/Services";
import Team from "../../Components/Team";

import { Container } from "./style";

const Home = () => {
  const projetos = [
    {
      title: "Qr Code Card",
      image:
        "https://github.com/PequenoJoohn/qr-code-card/raw/master/design/desktop-preview.jpg",
      url: "https://qr-code-card-pequenojoohn.vercel.app/",
      repository: "https://github.com/PequenoJoohn/qr-code-card",
    },
    {
      title: "Code Chella",
      image: "https://github.com/PequenoJoohn/code-chella/raw/main/screen.png",
      url: "https://code-chella.netlify.app/",
      repository: "https://github.com/PequenoJoohn/code-chella",
    },
  ];

  return (
    <Container>
      <AdSense />
      <PopUp />
      <Navbar />
      <Main />
      <About />
      <ComunityProject projects={projetos} />
      <Services />
      <Team />
      <Partner />
      <Budgets />
      <Footer />
      <Copyright />
    </Container>
  );
};

export default Home;
