import AOS from "aos";

import { useEffect } from "react";
import CardTeam from "../../Atoms/CardTeam";
import Title from "../../Atoms/Title";
import { Container } from "./style";

const Team = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const users = [
    {
      picture: "https://github.com/pequenojoohn.png",
      name: "Jonathan Matos",
      description: "A FullStack developer, technology enthusiast",
      role: "CEO",
      social: {
        twitter: "https://twitter.com/JoohnMatos",
        github: "https://github.com/pequenojoohn",
      },
    },
    {
      picture: "https://github.com/aninhafofazap.png",
      name: "Ana Victória",
      description: "A Frontend beginner",
      role: "CTO",
      social: {
        twitter: "https://twitter.com/AnaVickz",
        github: "https://github.com/aninhafofazap",
      },
    },
  ];

  return (
    <Container data-aos="fade-left">
      <Title title="Conheça a equipe" />
      <div>
        {users?.map((user) => (
          <CardTeam {...user} />
        ))}
      </div>
    </Container>
  );
};

export default Team;
