import { BsGithub, BsTwitter } from "react-icons/bs";
import { Container } from "./style";

interface ICardTeam {
  picture: string;
  name: string;
  description: string;
  role: string;
  social?: {
    twitter: string;
    github: string;
  };
}

const CardTeam = ({ picture, name, description, role, social }: ICardTeam) => {
  return (
    <Container>
      <img src={picture} alt={picture} />

      <h3>{name}</h3>

      <p>{description}</p>

      <p>{role}</p>

      <ul>
        {social?.twitter && (
          <li>
            <a href={social.twitter} target="_blank" rel="noopener noreferrer">
              <BsTwitter />
            </a>
          </li>
        )}
        {social?.github && (
          <li>
            <a href={social.github} target="_blank" rel="noopener noreferrer">
              <BsGithub />
            </a>
          </li>
        )}
      </ul>
    </Container>
  );
};

export default CardTeam;
