import styled from "styled-components";

export const Container = styled.div`
  background-color: #2f2f2f;
  width: 100%;
  max-width: 414px;
  height: 365px;
  border-radius: 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    border-radius: 50%;
    max-width: 64px;
    height: 64px;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  > p {
    text-align: center;
    width: 200px;

    &:nth-child(4) {
      margin-top: 1.5rem;
      text-transform: uppercase;
    }
  }

  ul {
    list-style: none;
    margin-top: 1rem;
    display: flex;
    li {
      padding: 0 0.2rem;
      a {
        color: #fff;
      }
    }
  }
`;
