import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  padding: 2.5rem;
  max-width: 1440px;
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    margin-bottom: 3rem;
  }

  > div {
    &:nth-child(1n + 2) {
      margin-bottom: 1rem;
    }

    &:last-child {
      width: 100%;

      div {
        &:nth-child(1) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media screen and (min-width: 959px) {
    > div {
      &:last-child {
        display: flex;
        width: 100%;
        justify-content: center;

        div {
          margin: 0 1rem;
          &:nth-child(1) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;
